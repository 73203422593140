<script>
import { mapGetters } from 'vuex';
import Favorite from '@shell/components/nav/Favorite';
import TabbedLinks from '@shell/components/TabbedLinks';
import TypeDescription from '@shell/components/TypeDescription';
import { get } from '@shell/utils/object';
import { AS, _YAML } from '@shell/config/query-params';
import ResourceLoadingIndicator from './ResourceLoadingIndicator';
import { HOMEAPP,MODELMANAGEMENTAPPS,DATAMANAGEMENTAPPS,SERVICEMANAGEMENTAPPS,TASKMANAGEMENTAPPS,SYSTEMMANAGEMENTAPPS,TRAINMANAGEMENTAPPS } from '@shell/config/routeconfig'
/**
 * Resource List Masthead component.
 */
export default {

  name: 'MastheadResourceList',

  components: {
    Favorite,
    TypeDescription,
    ResourceLoadingIndicator,
    TabbedLinks
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    favoriteResource: {
      type: String,
      default: null
    },
    schema: {
      type: Object,
      default: null,
    },
    typeDisplay: {
      type: String,
      default: null,
    },
    isCreatable: {
      type: Boolean,
      default: null,
    },
    isYamlCreatable: {
      type: Boolean,
      default: null,
    },
    createLocation: {
      type: Object,
      default: null,
    },
    yamlCreateLocation: {
      type: Object,
      default: null,
    },
    createButtonLabel: {
      type: String,
      default: null
    },
    loadResources: {
      type: Array,
      default: () => []
    },

    loadIndeterminate: {
      type: Boolean,
      default: false
    },

    showIncrementalLoadingIndicator: {
      type: Boolean,
      default: false
    },

    /**
     * Inherited global identifier prefix for tests
     * Define a term based on the parent component to avoid conflicts on multiple components
     */
    componentTestid: {
      type: String,
      default: 'masthead'
    }
  },

  data() {
    const params = { ...this.$route.params };

    const formRoute = { name: `${this.$route.name}-create`, params };

    const hasEditComponent = this.$store.getters['type-map/hasCustomEdit'](this.resource);

    const yamlRoute = {
      name: `${this.$route.name}-create`,
      params,
      query: { [AS]: _YAML },
    };

    return {
      formRoute,
      yamlRoute,
      hasEditComponent,
      HOMEAPP,MODELMANAGEMENTAPPS,DATAMANAGEMENTAPPS,SERVICEMANAGEMENTAPPS,TASKMANAGEMENTAPPS,SYSTEMMANAGEMENTAPPS,TRAINMANAGEMENTAPPS
    };
  },

  computed: {
    get,
    ...mapGetters(['isExplorer']),

    resourceName() {
      if (this.schema) {
        return this.$store.getters['type-map/labelFor'](this.schema);
      }

      return this.resource;
    },

    _typeDisplay() {
      if (this.typeDisplay !== null) {
        return this.typeDisplay;
      }

      if (!this.schema) {
        return '?';
      }

      return this.$store.getters['type-map/labelFor'](this.schema, 99);
    },

    _isYamlCreatable() {
      if (this.isYamlCreatable !== null) {
        return this.isYamlCreatable;
      }

      return this.schema && this._isCreatable && this.$store.getters['type-map/optionsFor'](this.resource).canYaml;
    },

    _isCreatable() {
      // Does not take into account hasEditComponent, such that _isYamlCreatable works
      if (this.isCreatable !== null) {
        return this.isCreatable;
      }

      // blocked-post means you can post through norman, but not through steve.
      if (this.schema && !this.schema?.collectionMethods.find((x) => ['blocked-post', 'post'].includes(x.toLowerCase()))) {
        return false;
      }

      return this.$store.getters['type-map/optionsFor'](this.resource).isCreatable;
    },

    _createLocation() {
      return this.createLocation || this.formRoute;
    },

    _yamlCreateLocation() {
      return this.yamlCreateLocation || this.yamlRoute;
    },

    _createButtonlabel() {
      return this.createButtonLabel || this.t('resourceList.head.create');
    },

    moduleTitle() {
      if (this.SYSTEMMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))) {
        return '系统管理';
      } else if (this.DATAMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))){
        return '数据管理';
      }else if (this.TASKMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))){
        return '任务管理';
      }else if (this.MODELMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))){
        return '智能算法服务';
      }else if (this.TRAINMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))){
        return '算法训练';
      }else if (this.SERVICEMANAGEMENTAPPS.some((route) => this.$route.fullPath.includes(route.route))){
        return '服务管理';
      }else {
        return '系统管理'
      }
    },

    ROUTESTYPEMAP() {
      const type = {
        '系统管理':this.SYSTEMMANAGEMENTAPPS,
        '数据管理':this.DATAMANAGEMENTAPPS,
        '任务管理':this.TASKMANAGEMENTAPPS,
        '智能算法服务':this.MODELMANAGEMENTAPPS,
        '算法训练':this.TRAINMANAGEMENTAPPS,
        '服务管理':this.SERVICEMANAGEMENTAPPS,
        '系统管理':this.SYSTEMMANAGEMENTAPPS,
      }
      return type
    }

  },
};
</script>

<template>
  <div class="custommasthead">
    <header class="masthead">
      <slot name="typeDescription">
        <TypeDescription :resource="resource" />
      </slot>
      <div class="title">
        <h1 class="m-0">
          {{ moduleTitle }}
        </h1>
        <ResourceLoadingIndicator v-if="showIncrementalLoadingIndicator" :resources="loadResources"
          :indeterminate="loadIndeterminate" />
      </div>
      <div v-show="!($route.query.type && ($route.query.type === 'data' || $route.query.type === 'train'))"
        class="actions-container">
        <slot name="actions">
          <div class="actions">
            <slot name="extraActions" />

            <slot name="createButton"  v-if="$route.fullPath !== '/c/local/explorer/apps.deployment?type=loadbalance'">
              <n-link v-if="hasEditComponent && _isCreatable" :to="_createLocation" class="btn role-primary"
                :data-testid="componentTestid + '-create'">
                {{ _createButtonlabel }}
              </n-link>
              <n-link v-else-if="_isYamlCreatable" :to="_yamlCreateLocation" class="btn role-primary"
                :data-testid="componentTestid + '-create-yaml'">
                {{ t("resourceList.head.createFromYaml") }}
              </n-link>
            </slot>
          </div>
        </slot>
      </div>
    </header>
    <div class="custnav">
      <TabbedLinks :tabList="ROUTESTYPEMAP[moduleTitle]"></TabbedLinks>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  align-items: center;
  display: flex;

  h1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: Microsoft Yahei;
  }
}

.masthead {
  position: relative;
}

.masthead::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -20px;
  width: calc(100% + 40px);
  border-bottom: 1px solid var(--border);
}
</style>
